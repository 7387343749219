body {
  margin: 0;
}

.target-jobs-list li::before {
    content: "\2022";
    color: var(--primary-900);
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: 0.5rem;
  }


.preview-image {
    width: 100%;
}

.sidebar {
    min-width: 25% !important;
}

.actions {
    opacity: 0;
}
.main-card:hover .actions{
    opacity: 1;
}

.certificate-card:hover .certificate-card-detail {
  display: block !important;
}

.ellipsis-multiline {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
.ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w-full-autoComplete input{
  width: 100%;
}

@supports (-webkit-line-clamp: 2) {
  .ellipsis-multiline {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
}

@supports (-webkit-line-clamp: 2) {
  .card .ellipsis-text.description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }
}


.p-inputtextarea {
  height: auto !important;
}

p,ul {
  margin: 0 !important;
}

.mySwiper {
  padding: 0px 15px 10px 7px !important;
}


div.swiper-button-disabled {
  display: none !important;
}

.truncate-p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-p-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 51px;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
   width: 100px; 
}

.border-amber {
  border-color: #FFBF00;
}
.bg-amber {
  background-color: #FFBF00;
}

.p-carousel-indicators {
  pointer-events: none;
}

.hero {
  background-image: url('../assets/landing/cover.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.p-dialog-header-maximize {
  display: none !important;
}

.preview-modal .p-dialog-content {
  background-color: var(--surface-ground) !important;
}

.preview-modal .p-dialog-header-close-icon  {
  font-weight: bold;
  font-size: 1.3rem;
  color: var(--primary-color);
}

.p-galleria-close {
  z-index: 999;
}

.slider-item-story {
  height: 40rem;
}

.qr-code-modal img {
  width: 100%;
}

.did-you-know-swiper .swiper-button-prev,
.did-you-know-swiper .swiper-button-next {
  display: none;
}

.storys-carousel .p-dialog-header {
  z-index: 10;
}

.cropper-image img {
  object-fit: cover;
}

.profile-tab .p-tabview-panels {
  background-color: transparent;
  padding: 0;
  box-shadow: none !important;
  margin-top: 2rem;
}



@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.blink-animation {
  animation: blink 2s infinite;
}

.job-title{
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  width: 60%;
}